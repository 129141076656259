// NOTE: All opt-web feature flags must start with opt- otherwise they will not work.
export enum LDFlags {
  OPT_DISABLE_TIPS = 'opt-disable-tips',
  OPT_LEAVE_PARTY_BUTTON = 'opt-leave-party-button',
  OPT_PARTY_PINS = 'opt-party-pins',
  OPT_PARTY_MEMBER_PERMALINK = 'opt-party-member-permalink',
  OPT_DISABLE_CREATE_ACCOUNT = 'opt-disable-create-account',
  OPT_TABS_REDESIGN_V2 = 'opt-tabs-redesign-v2',
  CUSTOM_PREAUTH = 'opt-custom-preauth',
  OPT_SIMULATE_APPLE_PAY = 'opt-simulate-apple-pay',
  OPT_REMOVE_APPLE_PAY_SHIPPING = 'opt-apple-pay-shipping',
  EMAIL_MARKETING_PREFERENCES = 'opt-email-marketing-preferences',
  OPT_TIP_FLOOR = 'opt-tip-floor',
  INTL_STP_PILOT = 'opt-intl-stp-pilot',
  GUEST_EXPRESS_MICROSPAS = 'opt-guest-express-microspas',
  OPT_TOAST_TAKEOUT_CTA = 'opt-toast-takeout-cta',
  OPT_DELETE_ACCOUNT = 'opt-delete-account',
  OPT_NO_SPLASH_SCREEN = 'opt-no-splash-screen',
  USE_SPI = 'opt-use-spi',
  OPT_INTL_SMS_SUPPORT = 'opt-intl-sms-support',
  OPT_INTL_EMAIL_SUPPORT = 'opt-intl-email-link',
  OPT_MENU_EXPERIMENT = 'opt-menu-experiment',
  OPT_PAYG_TABS = 'opt-payg-tabs',
  INTL_OPT_GOOGLE_PAY = 'opt-intl-googlepay',
  OPT_PAY_AND_GO = 'opt-pay-and-go',
  VSMB_RESTAURANTS = 'opt-pocket-toast-poc',
  OPT_SERVICE_CHARGE_WARNING = 'opt-service-charge-warning',
  OPT_MNP_TABS_SPA = 'opt-menu-and-pay-tabs-spa',
  QUICK_TABS_DATA_COLLECTION = 'opt-quick-tabs-data-collection',
  NO_SPLASH_NOAUTH = 'opt-remove-splash-noauth',
  SURCHARGING_TABS_STP = 'opt-web-surcharge-tabs-stp',
  UNIFIED_ONBOARDING = 'opt-unified-onboarding',
  INTL_STP_SPLIT_CHECK = 'opt-intl-split-evenly',
  INTL_ADYEN_BLOCK_REDIRECT = 'opt-intl-adyen-block-redirect',
  OPT_PAYG_SURCHARGING = 'opt-payg-surcharge',
  OPT_SPI_PREAUTH = 'opt-spi-preauth'
}

export type FlagMapObject = {
  [key in LDFlags]: boolean
}

// Treatments that override launch darkly settings.
// Useful for local testing.
// If values are here, they likely should be removed.
export const flagOverrides: Partial<FlagMapObject> = {}
